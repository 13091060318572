// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-contemporary-js": () => import("./../../../src/pages/contemporary.js" /* webpackChunkName: "component---src-pages-contemporary-js" */),
  "component---src-pages-exceptional-sales-js": () => import("./../../../src/pages/exceptional-sales.js" /* webpackChunkName: "component---src-pages-exceptional-sales-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-historical-js": () => import("./../../../src/pages/historical.js" /* webpackChunkName: "component---src-pages-historical-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-subscribe-successful-js": () => import("./../../../src/pages/subscribe-successful.js" /* webpackChunkName: "component---src-pages-subscribe-successful-js" */),
  "component---src-pages-user-favourite-artists-js": () => import("./../../../src/pages/user/favourite-artists.js" /* webpackChunkName: "component---src-pages-user-favourite-artists-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-user-saved-artworks-js": () => import("./../../../src/pages/user/saved-artworks.js" /* webpackChunkName: "component---src-pages-user-saved-artworks-js" */),
  "component---src-pages-virtual-tours-js": () => import("./../../../src/pages/virtual-tours.js" /* webpackChunkName: "component---src-pages-virtual-tours-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info_page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

