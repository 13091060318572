export const USER_LOGIN = `USER_LOGIN`;
export const USER_LOGOUT = `USER_LOGOUT`;
export const UPDATE_ARTIST_FOLLOW = `UPDATE_ARTIST_FOLLOW`;
export const UPDATE_ARTIST_FOLLOW_ALL = `UPDATE_ARTIST_FOLLOW_ALL`;
export const UPDATE_ARTWORK_SAVE = `UPDATE_ARTWORK_SAVE`;
export const UPDATE_LOCATIONS_ALL = `UPDATE_LOCATIONS_ALL`;
export const UPDATE_USER_INFO = `UPDATE_USER_INFO`;
export const UPDATE_USER_ADDRESS = `UPDATE_USER_ADDRESS`;
export const UPDATE_USER_SERVICES = `UPDATE_USER_SERVICES`;
export const UPDATE_USER_BUDGET = `UPDATE_USER_BUDGET`;
export const UPDATE_USER_ARTWORK_TYPES = `UPDATE_USER_ARTWORK_TYPES`;
export const UPDATE_USER_MAILCHIMP = `UPDATE_USER_MAILCHIMP`;

export const userLogin = (state, payload) => {
  const [data] = payload.current_user.data;

  const { id, type } = data;

  const {
    field_artist_of_interest,
    field_artist_type_preference,
    field_locations_ref,
    field_motivation,
    field_mailchimp_source,
    field_mailchimp_status,
    field_saved_artworks,
    field_services_that_interest_you,
    field_types_that_interest_you,
    field_address_paragraphs,
  } = data.relationships;

  //console.log('saved artworks', field_saved_artworks)

  const {
    title,
    field_email_address,
    field_phone_other_1,
    field_firstname,
    field_lastname,
    field_salutation,
    field_company,
    field_business_title,
    field_c_address_name,
    field_maximum_artwork_budget,
  } = data.attributes;

  const locations = field_locations_ref.data.map((item) => item.id);
  const artists = field_artist_of_interest.data.map((item) => item.id);
  const artistsType = field_artist_type_preference.data.map((item) => item.id);
  const artworks = field_saved_artworks.data.map((item) => item.id);
  const artworkType = field_types_that_interest_you.data.map((item) => item.id);
  const services = field_services_that_interest_you.data.map((item) => item.id);

  // check if current status uuid is that of subscribed
  let mailchimpStatus = false;
  if (field_mailchimp_status && field_mailchimp_status.data) {
    mailchimpStatus =
    field_mailchimp_status.data.id === 'e0ce27e0-4949-4b4b-bd9d-7df278028624';
  }

  // add paragraphs info to user fields
  let address_id, field_city, field_country, field_postal_code, field_street_and_number, field_province;

  try {
    address_id = field_address_paragraphs?.data?.id || field_address_paragraphs?.data[0]?.id;
  } catch(e) {
    console.error(e);
  }

  if (typeof field_address_paragraphs.data === "object") {
    field_city = field_address_paragraphs?.data?.attributes?.field_city
    field_country = field_address_paragraphs?.data?.attributes?.field_country
    field_postal_code = field_address_paragraphs?.data?.attributes?.field_postal_code
    field_street_and_number = field_address_paragraphs?.data?.attributes?.field_street_and_number
    field_province = field_address_paragraphs?.data?.attributes?.field_province
  } else if (field_address_paragraphs.data.length) {
    field_city = field_address_paragraphs.data[0].attributes.field_city;
    field_country = field_address_paragraphs.data[0].attributes.field_country;
    field_postal_code = field_address_paragraphs.data[0].attributes.field_postal_code;
    field_street_and_number = field_address_paragraphs.data[0].attributes.field_street_and_number;
    field_province = field_address_paragraphs.data[0].attributes.field_province;
  }

  const userInfo = {
    user: {
      id,
      type,
      title,
      field_firstname,
      field_lastname,
      field_email_address,
      field_phone_other_1,
      field_address_id: address_id,
      field_city,
      field_country,
      field_postal_code,
      field_province,
      field_street_and_number,
      field_salutation,
      field_company,
      field_business_title,
      field_c_address_name,
      field_maximum_artwork_budget,
      field_artwork_saved: artworks,
      field_artist_of_interest: artists,
      field_artist_type_preference: artistsType,
      field_services_that_interest_you: services,
      field_locations_ref: locations,
      field_motivation: field_motivation.data,
      field_mailchimp_source: field_mailchimp_source.data,
      field_mailchimp_status: mailchimpStatus,
      field_types_that_interest_you: artworkType,
      isLoggedIn: true,
    },
  };

  return userInfo;
};

export const userLogout = (state, payload) => {
  return { user: { isLoggedIn: false } };
};

export const userUpdateArtistFollow = (state, payload) => {
  const { artist_drupal_id } = payload;
  const newState = { ...state };

  if (newState.user.field_artist_of_interest.includes(artist_drupal_id)) {
    const itemIndex = newState.user.field_artist_of_interest.indexOf(
      artist_drupal_id
    );
    newState.user.field_artist_of_interest = [
      ...newState.user.field_artist_of_interest.slice(0, itemIndex),
      ...newState.user.field_artist_of_interest.slice(itemIndex + 1),
    ];
  } else {
    newState.user.field_artist_of_interest.push(artist_drupal_id);
  }

  return newState;
};

export const userUpdateArtistFollowAll = (state, payload) => {
  const { artists } = payload;
  const newState = { ...state };

  newState.user.field_artist_of_interest = [...artists];

  return newState;
};

export const userUpdateLocationsAll = (state, payload) => {
  const { locations } = payload;
  const newState = { ...state };

  newState.user.field_locations_ref = [...locations];

  return newState;
};

export const userUpdateArtworkSave = (state, payload) => {
  const { artwork_drupal_id } = payload;
  const newState = { ...state };

  if (newState.user.field_artwork_saved.includes(artwork_drupal_id)) {
    const itemIndex = newState.user.field_artwork_saved.indexOf(
      artwork_drupal_id
    );
    newState.user.field_artwork_saved = [
      ...newState.user.field_artwork_saved.slice(0, itemIndex),
      ...newState.user.field_artwork_saved.slice(itemIndex + 1),
    ];
  } else {
    newState.user.field_artwork_saved.push(artwork_drupal_id);
  }

  return newState;
};

export const userUpdateInfo = (state, payload) => {
  const { userInfo } = payload;
  const newState = { ...state };

  newState.user.field_firstname = userInfo.field_firstname;
  newState.user.field_lastname = userInfo.field_lastname;
  newState.user.field_phone_other_1 = userInfo.field_phone_other_1;
  newState.user.field_business_title = userInfo.field_business_title;
  newState.user.field_salutation = userInfo.field_salutation;
  newState.user.field_company = userInfo.field_company;

  return newState;
};

export const userUpdateAddress = (state, payload) => {
  const { userAddress } = payload;
  const newState = { ...state };

  newState.user.field_c_city = userAddress.field_c_city;
  newState.user.field_c_country = userAddress.field_c_country;
  newState.user.field_c_postal_code = userAddress.field_c_postal_code;
  newState.user.field_c_province = userAddress.field_c_province;
  newState.user.field_c_street_and_number =
    userAddress.field_c_street_and_number;

  return newState;
};

export const userUpdateServices = (state, payload) => {
  const { services } = payload;
  const newState = { ...state };

  newState.user.field_services_that_interest_you = [...services];

  return newState;
};

export const userUpdateBudget = (state, payload) => {
  const { budget } = payload;
  const newState = { ...state };

  newState.user.field_maximum_artwork_budget =
    budget.field_maximum_artwork_budget;

  return newState;
};

export const userUpdateArtworkTypes = (state, payload) => {
  const { types } = payload;
  const newState = { ...state };

  newState.user.field_types_that_interest_you = [...types];

  return newState;
};

export const userUpdateMailchimpStatus = (state, payload) => {
  const { status } = payload;
  const newState = { ...state };

  newState.user.field_mailchimp_status = status;

  return newState;
};
