import {
  USER_LOGIN,
  userLogin,
  USER_LOGOUT,
  userLogout,
  UPDATE_ARTIST_FOLLOW,
  userUpdateArtistFollow,
  UPDATE_ARTWORK_SAVE,
  userUpdateArtworkSave,
  UPDATE_ARTIST_FOLLOW_ALL,
  userUpdateArtistFollowAll,
  UPDATE_LOCATIONS_ALL,
  userUpdateLocationsAll,
  UPDATE_USER_INFO,
  userUpdateInfo,
  UPDATE_USER_ADDRESS,
  userUpdateAddress,
  UPDATE_USER_SERVICES,
  userUpdateServices,
  UPDATE_USER_BUDGET,
  userUpdateBudget,
  UPDATE_USER_ARTWORK_TYPES,
  userUpdateArtworkTypes,
  UPDATE_USER_MAILCHIMP,
  userUpdateMailchimpStatus,
} from './actions';

export const userDataReducer = (state = {}, action) => {
  if (action.type === USER_LOGIN) {
    return userLogin(state, action.payload);
  }
  if (action.type === USER_LOGOUT) {
    return userLogout(state, action.payload);
  }
  if (action.type === UPDATE_ARTIST_FOLLOW) {
    return userUpdateArtistFollow(state, action.payload);
  }
  if (action.type === UPDATE_ARTIST_FOLLOW_ALL) {
    return userUpdateArtistFollowAll(state, action.payload);
  }
  if (action.type === UPDATE_ARTWORK_SAVE) {
    return userUpdateArtworkSave(state, action.payload);
  }
  if (action.type === UPDATE_USER_INFO) {
    return userUpdateInfo(state, action.payload);
  }
  if (action.type === UPDATE_LOCATIONS_ALL) {
    return userUpdateLocationsAll(state, action.payload);
  }
  if (action.type === UPDATE_USER_ADDRESS) {
    return userUpdateAddress(state, action.payload);
  }
  if (action.type === UPDATE_USER_SERVICES) {
    return userUpdateServices(state, action.payload);
  }
  if (action.type === UPDATE_USER_BUDGET) {
    return userUpdateBudget(state, action.payload);
  }
  if (action.type === UPDATE_USER_ARTWORK_TYPES) {
    return userUpdateArtworkTypes(state, action.payload);
  }
  if (action.type === UPDATE_USER_MAILCHIMP) {
    return userUpdateMailchimpStatus(state, action.payload);
  }

  return state;
};
