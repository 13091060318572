import React, {useState, useEffect, useRef, useContext} from 'react';
import { globalHistory } from '@reach/router'

const ScrollContext = React.createContext();
export default ScrollContext;

export const ScrollProvider = ({children}) => {

    const SCROLL_THRESHOLD = 1;
    const SCROLL_RANGE = 100;

    const [ scrollDirection, setScrollDirection ] = useState(null);
    const [ scrollY, setScrollY ] = useState(0);
    const [ lastScrollY, setLastScrollY ] = useState(0);
    const [ scrolling, setScrolling ] = useState(false);

    // check if the page has changed
    // if so, reset stuff
    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') { 
                setScrollDirection('up');
                setScrollY(0);
                setLastScrollY(0);
            }
        })
    }, [setScrollDirection])

    // on document scroll, update 
    // window scroll position
    useEffect(() => {

            // if we're scrolling farther than the scroll threshold
            // determine the scroll direction and set it
            const determineScrollDirection = () => {
                if (scrollY !== lastScrollY) {
                    if (Math.abs(scrollY - lastScrollY) > SCROLL_THRESHOLD) {
                        let direction = 'up'
                        if ( scrollY > lastScrollY ) {
                            direction = 'down'
                        }
                        setScrollDirection(direction);
                    }
                }
                // always set to up, if within scrollRange
                if (scrollY < SCROLL_RANGE) {
                    setScrollDirection("up");
                }
            }

            const onScroll = e => {
              setLastScrollY(scrollY);
              setScrollY(e.target.documentElement.scrollTop);
              setScrolling(e.target.documentElement.scrollTop > scrollY);
              determineScrollDirection();
            };

            window.addEventListener("scroll", onScroll);
        
            return () => window.removeEventListener("scroll", onScroll);
            
    }, [scrollY, lastScrollY]);
    
    return (
        <ScrollContext.Provider value={
            {
                scrollDirection,
                scrollY,
            }}
        >
            {children}
        </ScrollContext.Provider>
    )
}

export const ScrollContainer = ({children, className, force = false, detectWithin = 1}) => {

    const SCROLL_CONTEXT = useContext(ScrollContext)
    let scrollY = 0;
    if (SCROLL_CONTEXT) {
        scrollY = SCROLL_CONTEXT.scrollY;
    }
    const [ visibility, setVisibility ] = useState(force);
    const [ offsetTop, setOffsetTop ] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef) {
            const { y, height } = containerRef.current.getBoundingClientRect();
            setOffsetTop({
                y: y,
                height: height
            })
        }
    }, [containerRef]);

    useEffect(() => {
        if (containerRef && offsetTop) {
            const { pageYOffset, innerHeight } = window
            const viewport = pageYOffset + innerHeight
            const detectionSensitivity = detectWithin / 100;
            const ot = offsetTop.y + ( offsetTop.height * detectionSensitivity )
            if (viewport >= ot) {
                setVisibility(true);
            } else {
                setVisibility(false);
            }
        }
    }, [containerRef, scrollY, detectWithin, offsetTop])

    return (
        <div
            ref={containerRef}
            className={`scrollcontainer ${className ? className : ''} ${visibility ? 'visible' : 'invisible'}`} 
        >
            {children}
        </div>
    )
}