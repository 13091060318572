import '@fontsource/fira-sans/100.css' //  Weight thin 100
import '@fontsource/fira-sans/400.css' //  Weight regular 400
import '@fontsource/fira-sans/500.css' //  Weight bold 500
import '@fontsource/fira-sans/600.css' //  Weight heavy 600
import '@fontsource/raleway/300.css' //  Weight light 300
import '@fontsource/raleway/400.css' //  Weight light 300
import '@fontsource/raleway/500.css' //  Weight light 300
import '@fontsource/raleway/600.css' //  Weight heavy 600
import '@fontsource/raleway/700.css' //  Weight heavy 600
import '@fontsource/raleway/800.css' //  Weight heavy 600
import '@fontsource/fira-sans/300-italic.css' //  Weight thin italic 300 not sure which font family used here

import React from 'react';
import { UserProvider } from './src/context/user/context';
import { ScrollProvider } from './src/context/scrollBehavior'

export const wrapRootElement = ({ element }) => (
  <UserProvider><ScrollProvider>{element}</ScrollProvider></UserProvider>
);