import React, { createContext, useReducer } from 'react';
import { userDataReducer } from './reducers';
import {
  USER_LOGIN,
  USER_LOGOUT,
  UPDATE_ARTIST_FOLLOW,
  UPDATE_ARTWORK_SAVE,
  UPDATE_ARTIST_FOLLOW_ALL,
  UPDATE_LOCATIONS_ALL,
  UPDATE_USER_INFO,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_SERVICES,
  UPDATE_USER_BUDGET,
  UPDATE_USER_ARTWORK_TYPES,
  UPDATE_USER_MAILCHIMP,
} from './actions';

export const UserContext = createContext();

const initialState = { user: { isLoggedIn: false } };

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userDataReducer, initialState);

  const userLogin = (current_user) => {
    dispatch({
      type: USER_LOGIN,
      payload: {
        current_user,
      },
    });
  };

  const userLogout = () => {
    dispatch({
      type: USER_LOGOUT,
    });
  };

  const userUpdateArtistFollow = (artist_drupal_id) => {
    dispatch({
      type: UPDATE_ARTIST_FOLLOW,
      payload: {
        artist_drupal_id,
      },
    });
  };

  const userUpdateArtworkSave = (artwork_drupal_id) => {
    dispatch({
      type: UPDATE_ARTWORK_SAVE,
      payload: {
        artwork_drupal_id,
      },
    });
  };

  const userUpdateArtistFollowAll = (artists) => {
    dispatch({
      type: UPDATE_ARTIST_FOLLOW_ALL,
      payload: {
        artists,
      },
    });
  };

  const userUpdateLocationsAll = (locations) => {
    dispatch({
      type: UPDATE_LOCATIONS_ALL,
      payload: {
        locations,
      },
    });
  };

  const userUpdateInfo = (userInfo) => {
    dispatch({
      type: UPDATE_USER_INFO,
      payload: {
        userInfo,
      },
    });
  };

  const userUpdateAddress = (userAddress) => {
    dispatch({
      type: UPDATE_USER_ADDRESS,
      payload: {
        userAddress,
      },
    });
  };

  const userUpdateServices = (services) => {
    dispatch({
      type: UPDATE_USER_SERVICES,
      payload: {
        services,
      },
    });
  };

  const userUpdateBudget = (budget) => {
    dispatch({
      type: UPDATE_USER_BUDGET,
      payload: {
        budget,
      },
    });
  };

  const userUpdateArtworkTypes = (types) => {
    dispatch({
      type: UPDATE_USER_ARTWORK_TYPES,
      payload: {
        types,
      },
    });
  };

  const userUpdateMailchimpStatus = (status) => {
    dispatch({
      type: UPDATE_USER_MAILCHIMP,
      payload: {
        status,
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        state,
        userLogin,
        userLogout,
        userUpdateArtistFollow,
        userUpdateArtistFollowAll,
        userUpdateArtworkSave,
        userUpdateLocationsAll,
        userUpdateInfo,
        userUpdateAddress,
        userUpdateServices,
        userUpdateBudget,
        userUpdateArtworkTypes,
        userUpdateMailchimpStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
